import axios from 'axios'

class FileIoService {
	async post(file) {
		const config = {
			headers: {
				'Authorization': 'Bearer ' + process.env.VUE_APP_FILE_IO_TOKEN,
				'Content-Type': 'multipart/form-data'
			}
		}
		const parametres = {
			autoDelete: false,
			expires: '30d',
			file: file
		}
		try {
			const response = await axios.post("https://file.io/", parametres, config);
			if (response.data.status === 400) {
				return ("error")
			} else return new Promise(function(resolve) {
				resolve ([response.data.link, response.data.expires]);
			});
		} catch (error) {
			return ("error")
		}
	}
}

export default new FileIoService;

<template>
  <FormComp/>
</template>

<script>
import FormComp from '@/components/FormComp.vue'

export default {
  name: 'HomeView',
  components: {
    'FormComp': FormComp,
  }
}
</script>

<style>
</style>

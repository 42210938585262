<template>
  <nav>
    <router-link @click="reload" to="/">
      <div class="container">
        <img class="img img-large" :src="largeImageSrc" alt="">
        <img class="img img-small" :src="smallImageSrc" alt="">
        <h1 class="title">Check my House</h1>
        <h2 class="subtitle is-3">Envoi de fichiers</h2>
      </div>
    </router-link>
  </nav>
	<router-view/>
</template>

<script>

export default {
	data() {
		return {
		largeImageSrc: require('@/assets/bandeau-300x100.png'),
		smallImageSrc: require('@/assets/logo.png')
		}
	},
	methods: {
		reload() {
			location.replace("https://envoyermesdocuments.checkmyhouse.fr/");
		}
	}
}

</script>
<style>
@import "@/assets/bulma.css";
@import "@/assets/all.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #dcc397;
}

.container {
  position: relative;
  padding: 20px;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  margin: 30px;
  max-width: 50%;
  max-height: 50%;
}

.title, .subtitle {
  color: white;
  font-size: calc(20px + 0.390625vw);
}

@media screen and (max-width: 768px) {
  .img-large {
    display: none;
  }

  .img-small {
    display: block;
    max-width: 40%;
    max-height: 40%;
    margin: 10px;
  }
}
</style>

<template>
	<div class="hero-body success subtitle is-2 ">
    Mail envoyé à l'équipe Check my House!<br>Merci pour votre confiance
	</div>
</template>

<style>
.success {
  color: seagreen;
  background-color: white;
}
</style>
